@tailwind base;
@tailwind components;
@tailwind utilities;

@mixin base {
	@apply py-2 px-4 rounded w-full;
	font-size: 14px;

	@media (min-width: 768px) { 
		font-size: 18px;
	};
}

@mixin close {
	@apply absolute top-4 right-4 rounded-full text-primary p-1 w-6 h-6 sm:p-2 sm:h-8 sm:w-8;
}
