@use 'colors';
@tailwind base;
@tailwind components;
@tailwind utilities;

.text-primary {
	color: $white;
}

.text-primary-dark {
	color: $black;
}

.yellow-font {
	color: $yellow;
}

.green-font {
	color: $green;
}

.grey-font {
	color: $lightestGrey;
}

.yellow-link {
	color: #cdbf7d;
	cursor: pointer;
}

.yellow-link:hover {
	color: $yellow;
}
