$black: #2a272a;
$white: #f1f3f9;
$darkestGrey: #353135;
$darkGrey: #736f75;
$lightGrey: #9d9ba1;
$lightestGrey: #c7c7cd;

$green: #609b94;
$lightestGreen: #dff0f3;
$lightGreen: #a8e4e0;
$darkGreen: #4e7471;

$yellow: #f8e797;
$darkestYellow: #443f38;
$lightestYellow: #fdfbec;
$darkYellow: #b8ab76;

