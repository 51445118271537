@tailwind base;
@tailwind components;
@tailwind utilities;

@mixin base {
	@apply flex;
	border-radius: 3rem;
}

@mixin header {
	@apply flex flex-col text-3xl sm:text-4xl text-center tracking-tight md:text-2xl;
}

@mixin body {
	font-size: 14px;

	@media (min-width: 640px) { 
		font-size: 18px;
	}
}

@mixin label {
	@apply flex flex-col items-center gap-y-2 w-full mx-auto;
	max-width: 275px;
}

@mixin content {
	@apply flex flex-col text-center mx-auto gap-y-4;
	max-width: 270px;

	@media (min-width: 640px) { 
		max-width: 370px;
	}
}

@mixin buttonzone {
	@apply flex flex-col gap-y-2 w-full justify-center; 
}

@mixin base2 {
	@apply flex justify-center flex-col text-xs mx-auto gap-y-4 md:text-base md:gap-y-8 md:p-12 md:max-w-lg;

	padding: 2.5rem;
	border-radius: 3rem;
}
